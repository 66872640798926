<template>
  <b-row>
    <b-col cols="12">
      <div v-if="survey && !surveyIsSent" class="container">
        <b-card class="justify-content-center p-3">
          <div class="text-center">
            <h3 style="font-weight: 600">แบบสอบถามเพื่อติดตามประเมินผล</h3>
            <h3 style="font-weight: 600; line-height: 1.5">
              โครงการ : การศึกษาเพื่อต่อต้านการใช้ยาเสพติดในเด็กนักเรียน
              (D.A.R.E. ประเทศไทย)
            </h3>
            <h3
              v-if="survey.description"
              class="text-danger"
              style="font-weight: 600"
            >
              *{{ survey.description }}
            </h3>
          </div>
        </b-card>

        <br />

        <!-- <div
        class="mb-5 row"
        style="align-items: baseline; justify-content: center"
      >
        <h5 style="font-weight: 600">
          โรงเรียน..........................ชั้น.........................
        </h5>
        <h5 class="mx-2" style="font-weight: 600">เพศ</h5>
        <b-form-radio-group
          id="radio-sex"
          v-model="selected"
          :options="options"
          name="radio-options-sex"
          style="font-weight: 600"
        ></b-form-radio-group>
        <h5 style="font-weight: 600">จำนวนนักเรียน............คน</h5>
      </div> -->

        <b-card class="justify-content-center mb-5 p-3">
          <div v-if="loadTeachingDare" class="d-flex justify-content-center">
            <div class="spinner-border" role="status">
              <span class="sr-only">Loading...</span>
            </div>
          </div>

          <div v-else class="">
            <h4 class="mb-3 text-danger" style="font-weight: 600">
              ข้อมูลทั่วไป
            </h4>
            <div class="ml-4" style="font-weight: 600">
              <h5>
                ประจำภาคเรียนที่:
                {{
                  this.teachingDare.fiscal_year && this.teachingDare.term
                    ? `${this.teachingDare.term}/${this.teachingDare.fiscal_year}`
                    : "........................................"
                }}
              </h5>
              <h5>
                โรงเรียน :
                {{
                  this.teachingDare.m_school
                    ? this.teachingDare.m_school.m_school_name
                    : "........................................"
                }}
              </h5>
              <h5>
                ห้องเรียน :
                {{
                  this.teachingDare.m_class.m_class_name
                    ? `${this.teachingDare.m_class.m_class_name}`
                    : "........................................"
                }}
              </h5>
              <h5>
                จำนวนนักเรียน :
                {{
                  this.teachingDare.actual_student
                    ? `${this.teachingDare.actual_student} คน`
                    : "........................................ คน"
                }}
              </h5>
              <span
                v-if="
                  this.teachingDare.teacher_dares &&
                  this.teachingDare.teacher_dares.length > 0
                "
              >
                <h5
                  v-for="teacher_dare in this.teachingDare.teacher_dares"
                  :key="`teacher_dares_${teacher_dare.id}`"
                >
                  อาจารย์ประจำชั้น/อาจารย์ผู้ควบคุม :
                  {{ teacher_dare.first_name }}
                  {{ teacher_dare.last_name }}
                </h5>
              </span>
              <span v-else>
                <h5>
                  อาจารย์ประจำชั้น/อาจารย์ผู้ควบคุม :
                  ........................................
                </h5>
              </span>
              <h5>
                ผู้อำนวยการโรงเรียน/ผู้บริหารโรงเรียน :
                {{
                  this.teachingDare.director_dares &&
                  this.teachingDare.director_dares.length > 0
                    ? `${this.teachingDare.director_dares[0].first_name} ${this.teachingDare.director_dares[0].last_name}`
                    : "........................................"
                }}
              </h5>
              <h5>
                ยศ ชื่อ สกุล (ครูตำรวจ D.A.R.E. ผู้เข้าสอน) :
                {{
                  this.teachingDare.user_profile
                    ? `${this.teachingDare.user_profile.first_name} ${this.teachingDare.user_profile.last_name}`
                    : "...................."
                }}
                ตำแหน่ง
                {{
                  this.teachingDare.user_profile &&
                  this.teachingDare.user_profile.position_name
                    ? this.teachingDare.user_profile.position_name
                    : "...................."
                }}

                สังกัด .............................
              </h5>
            </div>
          </div>
        </b-card>

        <b-card class="justify-content-center mb-5 p-3">
          <div v-if="loadSurvey" class="d-flex justify-content-center">
            <div class="spinner-border" role="status">
              <span class="sr-only">Loading...</span>
            </div>
          </div>

          <div v-else class="">
            <h4 class="mb-3 text-danger" style="font-weight: 600">
              สำหรับคำถามการให้คะแนน
            </h4>
            <div class="ml-4" style="font-weight: 600">
              <h5>ดีที่สุด = 5 คะแนน</h5>
              <h5>ดีมาก = 4 คะแนน</h5>
              <h5>ดี = 3 คะแนน</h5>
              <h5>ปานกลาง = 2 คะแนน</h5>
              <h5>พอใช้ = 1 คะแนน</h5>
            </div>
          </div>
        </b-card>

        <b-card
          class="justify-content-center p-3"
          v-if="questions"
          style="background: #f8f9ff94"
        >
          <b-card
            v-for="question in questions"
            :key="`question_${question.id}`"
            class="mb-4 p-3"
          >
            <div v-if="question && question.survey_topic_no">
              <h5 class="mb-3 text-primary" style="font-weight: 600">
                {{ question.survey_topic_no }}.
                {{ question.survey_topic_title }}
              </h5>

              <div
                v-for="questionTopic in question.questions"
                :key="`question_${questionTopic.id}`"
              >
                <!-- Choice -->
                <div
                  class=""
                  v-if="
                    questionTopic.question_type == 'choice' &&
                    questionTopic.question_choices &&
                    questionTopic.question_choices.length > 0
                  "
                >
                  <h5 class="ml-3">
                    {{ question.survey_topic_no }}.{{
                      questionTopic.question_no
                    }}
                    {{ questionTopic.question_title }}
                  </h5>

                  <b-form-group class="ml-5">
                    <b-form-radio
                      class="my-3 sub-survey-topic"
                      v-for="choice in questionTopic.question_choices"
                      :key="`question_choice_${choice.id}`"
                      :name="`question_choice_${choice.id}`"
                      :value="choice.id"
                      v-model="questionTopic.question_answers"
                    >
                      <h5>
                        {{ choice.question_choice_title }}
                      </h5>
                    </b-form-radio>
                  </b-form-group>
                </div>

                <!-- Text -->
                <div v-if="questionTopic.question_type == 'text'" class="">
                  <h5 class="ml-4">
                    {{ question.survey_topic_no }}.{{
                      questionTopic.question_no
                    }}
                    {{ questionTopic.question_title }}
                  </h5>
                  <textarea
                    class="form-control my-3 ml-5"
                    :id="`question_text_${questionTopic.id}`"
                    rows="3"
                    placeholder="ตอบ"
                    style="width: -webkit-fill-available"
                    v-model="questionTopic.question_answers"
                  ></textarea>
                </div>

                <!-- Rating -->
                <div
                  v-if="questionTopic.question_type == 'rating'"
                  class="mb-3"
                >
                  <h5 class="ml-4">
                    {{ question.survey_topic_no }}.{{
                      questionTopic.question_no
                    }}
                    {{ questionTopic.question_title }}
                  </h5>

                  <!-- <v-app style="min-height: 100vh;">
                  <v-slider step="10" thumb-label ticks></v-slider>
                </v-app> -->

                  <div class="px-5">
                    <vue-slide-bar
                      v-model="questionTopic.question_answers"
                      :data="slider.data"
                      :range="slider.range"
                      :labelStyles="{
                        color: '#4a4a4a',
                        backgroundColor: '#4a4a4a',
                      }"
                      :processStyle="{
                        backgroundColor: genColors(
                          questionTopic.question_answers
                        ),
                      }"
                      :tooltipStyles="{
                        backgroundColor: genColors(
                          questionTopic.question_answers
                        ),
                        borderColor: genColors(questionTopic.question_answers),
                      }"
                    >
                    </vue-slide-bar>
                  </div>

                  <!-- <v-rating
                  v-model="questionTopic.question_answers"
                  length="5"
                  class="sub-survey-topic"
                >
                  <template v-slot:item="props">
                    <v-icon
                      large
                      class="v_reting__custom"
                      :style="
                        props.isFilled
                          ? `color: ${genColor(
                              props.index,
                              questionTopic.question_answers
                            )}`
                          : 'color: rgb(118, 118, 118);'
                      "
                      v-text="`mdi-numeric-${props.index + 1}-box`"
                      @click="props.click"
                    ></v-icon>
                  </template>
                </v-rating> -->
                </div>
              </div>
            </div>

            <div v-else>
              <h5 class="text-primary" style="font-weight: 600">
                {{ question.question_no }}. {{ question.question_title }}
              </h5>

              <!-- Choice -->
              <div
                class=""
                v-if="
                  question.question_type == 'choice' &&
                  question.question_choices &&
                  question.question_choices.length > 0
                "
              >
                <b-form-group class="ml-4">
                  <b-form-radio
                    class="my-3"
                    v-for="choice in question.question_choices"
                    :key="`question_choice_${choice.id}`"
                    :name="`question_choice_${choice.id}`"
                    :value="choice.id"
                    v-model="question.question_answers"
                  >
                    <h5>
                      {{ choice.question_choice_title }}
                    </h5>
                  </b-form-radio>
                </b-form-group>
              </div>

              <!-- Text -->
              <div v-if="question.question_type == 'text'" class="">
                <textarea
                  class="form-control my-3 ml-4"
                  :id="`question_text_${question.id}`"
                  rows="3"
                  placeholder="ตอบ"
                  style="width: -webkit-fill-available"
                  v-model="question.question_answers"
                ></textarea>
              </div>

              <!-- Rating -->
              <div v-if="question.question_type == 'rating'" class="mb-3">
                <vue-slide-bar
                  v-model="question.question_answers"
                  :data="slider.data"
                  :range="slider.range"
                  :labelStyles="{
                    color: '#4a4a4a',
                    backgroundColor: '#4a4a4a',
                  }"
                  :processStyle="{
                    backgroundColor: genColors(question.question_answers),
                  }"
                  :tooltipStyles="{
                    backgroundColor: genColors(question.question_answers),
                    borderColor: genColors(question.question_answers),
                  }"
                >
                </vue-slide-bar>

                <!-- <v-rating
                v-model="question.question_answers"
                length="5"
                class="ml-4"
              >
                <template v-slot:item="props">
                  <v-icon
                    large
                    class="v_reting__custom"
                    :style="
                      props.isFilled
                        ? `color: ${genColor(
                            props.index,
                            question.question_answers
                          )}`
                        : 'color: rgb(118, 118, 118);'
                    "
                    v-text="`mdi-numeric-${props.index + 1}-box`"
                    @click="props.click"
                  ></v-icon>
                </template>
              </v-rating> -->
              </div>
            </div>
          </b-card>
          <div class="d-flex justify-content-center">
            <!-- <b-button
            variant="secondary"
            :disabled="isSubmiting"
            @click="
              () => {
                this.$router.push('home');
              }
            "
            >ยกเลิก</b-button
          > -->

            <b-button
              variant="danger"
              style="border-radius: 30px"
              @click="submitSurvey"
              :disabled="isSubmiting"
            >
              <b-spinner
                v-if="isSubmiting"
                small
                label="submiting..."
              ></b-spinner>
              <span v-else>ส่งแบบสอบถาม</span>
            </b-button>
          </div>
        </b-card>
      </div>

      <div v-else class="container">
        <b-card class="justify-content-center p-3">
          <div class="my-5 text-center">
            <h4>ขอขอบคุณอย่างยิ่ง ที่กรุณาร่วมมือตอบแบบสอบถามฉบับนี้</h4>
            <h4>
              กองบัญชาการตำรวจปราบปรามยาเสพติดจะรวบรวมข้อมูลเพื่อปรับปรุงประสิทธิภาพการดำเนินโครงการ
              D.A.R.E. ประเทศไทยต่อไป
            </h4>
          </div>
        </b-card>
      </div>
    </b-col>
  </b-row>
</template>

<script>
import { Survey, TeachingDare } from "../../models";
import { mapKeys } from "lodash";
import VueSlideBar from "vue-slide-bar";
export default {
  components: {
    VueSlideBar,
  },
  props: {
    surveyId: Number,
    teachingDareId: Number,
  },

  watch: {
    surveyId: {
      immediate: true,
      handler: "getSurvey",
    },

    teachingDareId: {
      immediate: true,
      handler: "getTeachingDare",
    },
  },

  data() {
    return {
      survey: {},
      surveyTopics: [],
      options: [
        { text: "ชาย", value: "male" },
        { text: "หญิง", value: "female" },
      ],
      selected: "",
      questions: {},
      teachingDare: {},
      colors: ["#ea484d", "#ef874c", "#f8c43d", "#9fcd35", "#5aaf2b"],
      loadTeachingDare: false,
      loadSurvey: false,
      isSubmiting: false,
      surveyIsSent: false,
      slider: {
        lineHeight: 10,
        data: [null, 1, 2, 3, 4, 5],
        range: [
          {
            label: "",
            isHide: true,
          },
          {
            label: "พอใช้",
          },
          {
            label: "ปานกลาง",
          },
          {
            label: "ดี",
          },
          {
            label: "ดีมาก",
          },
          {
            label: "ดีที่สุด",
          },
        ],
      },
    };
  },

  computed: {},

  methods: {
    async submitSurvey() {
      let answer = [];
      try {
        this.isSubmiting = true;
        for (const key in this.questions) {
          if (Object.hasOwnProperty.call(this.questions, key)) {
            const question = this.questions[key];
            if (question.questions) {
              let isQuestions = question.questions;
              for (let index = 0; index < isQuestions.length; index++) {
                const element = isQuestions[index];
                if (
                  element &&
                  (element.question_type == "rating" ||
                    element.question_type == "choice") &&
                  !element.question_answers
                ) {
                  return this.$toast.error("กรุณาตอบให้ครบทุกข้อ");
                }

                if (
                  element &&
                  element.question_type == "text" &&
                  !element.question_answers
                ) {
                  answer.push({
                    questionId: element.id.toString(),
                    answer: "",
                  });
                }

                if (element && element.question_answers) {
                  answer.push({
                    questionId: element.id.toString(),
                    answer: element.question_answers.toString(),
                  });
                }
              }
            } else {
              if (
                question &&
                (question.question_type == "rating" ||
                  question.question_type == "choice") &&
                !question.question_answers
              ) {
                return this.$toast.error("กรุณาตอบให้ครบทุกข้อ");
              }

              if (
                question &&
                question.question_type == "text" &&
                !question.question_answers
              ) {
                answer.push({
                  questionId: question.id.toString(),
                  answer: "",
                });
              }

              if (question && question.question_answers) {
                answer.push({
                  questionId: question.id.toString(),
                  answer: question.question_answers.toString(),
                });
              }
            }
          }
        }
        const { response } = await Survey.api().createAnswer({
          answers: answer,
          teachingDareId: this.teachingDareId,
        });
        if (
          response &&
          response.data &&
          response.data.response_status == "SUCCESS"
        ) {
          this.$toast.success("ขอบคุณที่ร่วมทำแบบสอบถาม");
          this.surveyIsSent = true;
        }
      } catch (error) {
        return this.$toast.error(error);
      } finally {
        this.isSubmiting = false;
      }
    },

    genColors(v) {
      if (v == 1) {
        return this.colors[0];
      }
      if (v == 2) {
        return this.colors[1];
      }
      if (v == 3) {
        return this.colors[2];
      }
      if (v == 4) {
        return this.colors[3];
      }
      if (v == 5) {
        return this.colors[4];
      }
      return `#4a4a4a`;
    },

    genColor(i, v) {
      if (i == v - 1) {
        return this.colors[i];
      }
      if (i == v - 1) {
        return this.colors[i];
      }
      if (i == v - 1) {
        return this.colors[i];
      }
      if (i == v - 1) {
        return this.colors[i];
      }
      if (i == v - 1) {
        return this.colors[i];
      }
      return `rgb(118, 118, 118);`;
    },

    async getSurvey(surveyId) {
      if (!surveyId) return;
      try {
        this.loadSurvey = true;
        let { response } = await Survey.api().findAll({ id: surveyId });
        if (response && response.data) {
          const { data } = response.data;
          this.survey = data[0];
          let newQuestions = data[0].questions.filter(function (question) {
            return question.surveyTopicId == null;
          });
          let tmpQuestionsAll;
          let tmpQuestions = mapKeys(newQuestions, function (o) {
            return o.question_no;
          });
          const { survey_topics } = data[0];
          if (survey_topics) {
            let tmpSurveyTopics = mapKeys(survey_topics, function (o) {
              return o.survey_topic_no;
            });
            tmpQuestionsAll = { ...tmpQuestions, ...tmpSurveyTopics };
          } else {
            tmpQuestionsAll = { ...tmpQuestions };
          }
          this.questions = { ...tmpQuestionsAll };
        }
      } catch (error) {
        console.error(error);
      } finally {
        this.loadSurvey = false;
      }
    },

    async getTeachingDare(teachingDareId) {
      if (!teachingDareId) return;
      try {
        this.loadTeachingDare = true;
        let { response } = await TeachingDare.api().findOne(teachingDareId);
        if (response && response.data) {
          const { data } = response.data;
          this.teachingDare = data;
        }
      } catch (error) {
        console.error(error);
      } finally {
        this.loadTeachingDare = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.v_reting__custom {
  border: none !important;
  background: transparent !important;
}

.v_reting__custom:focus {
  border: none !important;
  background: transparent !important;
  outline: none !important;
}

.sub-survey-topic {
  // margin-left: 3rem !important;
  @media screen and (max-width: 400px) {
    margin-left: 0 !important;
    text-align: center !important;
  }
}
</style>
